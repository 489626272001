/* Navbar styles */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1a1a1a;
    padding: 10px 20px;
    color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .navbar-left,
  .navbar-center,
  .navbar-right {
    display: flex;
    align-items: center;
  }
  
  .brand {
    display: flex;
    align-items: center;
  }
  
  .brand-logo {
    height: 30px;
    margin-right: 10px;
  }
  
  .brand-name {
    font-size: 1.2rem;
    font-weight: bold;
    color: #ffffff;
  }
  
  .search-bar {
    display: flex;
    align-items: center;
    background-color: #2c2c2c;
    padding: 5px 10px;
    border-radius: 20px;
  }
  
  .search-icon {
    color: #888;
    margin-right: 5px;
  }
  
  .search-input {
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    width: 200px;
  }
  
  .search-input::placeholder {
    color: #888;
  }
  
  .navbar-right .icon {
    font-size: 1.2rem;
    color: #ffffff;
    margin-right: 15px;
    cursor: pointer;
  }
  
  .navbar-right .icon:hover {
    color: #cccccc;
  }
  
  .profile {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .profile-icon {
    font-size: 1.5rem;
    margin-right: 5px;
  }
  
  .profile-name {
    font-size: 1rem;
    font-weight: bold;
  }
  