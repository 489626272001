.right-panel {
  flex: 1;
  padding: 20px;
  background-color: #f9f9f9;
  border-left: 1px solid #ddd;
  overflow-y: auto;
}

.right-panel-header {
  position: sticky;
  top: 0;
  background-color: #f9f9f9;
  z-index: 10;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}

.right-panel-header h3 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.quote-card {
  background: #fff;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.quote-card-header {
  margin-bottom: 10px;
}

.quote-card-header h4 {
  margin: 0;
  font-size: 1.1rem;
  color: #007bff;
}

.quote-id {
  font-size: 0.9rem;
  color: #666;
}

.quote-card-header p {
  margin: 5px 0;
  color: #666;
  font-size: 0.9rem;
}

.quote-card-body {
  margin-bottom: 10px;
}

.quote-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.quote-field {
  display: flex;
  align-items: center;
}

.quote-field label {
  font-weight: bold;
  color: #333;
  margin-left: 10px;
  flex: 1;
}

.quote-field span {
  color: #555;
  flex: 1;
}

.icon {
  color: gray;
  font-size: 1rem;
}

.quote-card-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.quote-card-footer button {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.quote-card-footer button:hover {
  background: #0056b3;
}
.quote-card-remove {
  background: red!important;
}


.price-field {
  font-weight: bold;
  font-size: 1.2rem;
  color: #555;
}


.price-value {
  font-weight: bold;
  font-size: 1.4rem;
  color: #007bff!important; /* Change to #f39c12 for orange */
}


