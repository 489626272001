/* App.css */

body {
  font-family: Arial, sans-serif;
  background-color: #f5f7fa;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 600px;
  /* margin: 40px auto; */
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  flex: 2;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.main-container {
  display: flex;
  height: 100vh;
}


.right-panel {
  flex: 1;
  padding: 20px;
  border-left: 1px solid #ddd;
}


.header {
  font-size: 24px;
  text-align: center;
  color: #333;
}

.form-section {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fafafa;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.form-section h2 {
  color: #666;
  font-size: 18px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.form-group label {
  flex: 1;
  color: #555;
  font-weight: 500;
}

.dropdown, .input-field {
  flex: 2;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.dropdown:focus, .input-field:focus {
  border-color: #007bff;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  /* height: 100%; */
}

.dropdown {
  background-color: #f9f9f9;
  cursor: pointer;
}

.input-field {
  background-color: #ffffff;
}

/* Updated styling for the Underlyings search input */
.large-input {
  width: calc(100% - 20px); /* Adjust width to stay within parent padding */
  padding: 12px;  
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 8px;
  background-color: #f9f9f9;
  box-sizing: border-box; /* Ensures padding is included within the width */
}

.large-input:focus {
  border-color: #007bff;
}

/* Styling for Font Awesome icons */
.icon {
  margin-right: 8px;
  color: #007bff;
  font-size: 1.2em;
}

/* Button styling */
.button-container {
  text-align: right;
  margin-top: 20px;
}

.quote-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.quote-button:hover {
  background-color: #0056b3;
}

.form-group input[type="datetime-local"] {
  /* width: calc(100% - 20px);;\ */
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-group input[type="datetime-local"]:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

.form-group input[type="datetime-local"]::placeholder {
  color: #aaa;
  font-style: italic;
}

.form-group input[type="datetime-local"]:hover {
  border-color: #0056b3;
}

@media (max-width: 768px) {
  .form-group input[type="datetime-local"] {
    font-size: 0.9rem;
    padding: 8px;
  }
}

/* .form-section {
  margin: 20px auto;
  width: 400px;
  font-family: Arial, sans-serif;
} */

/* h2 {
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
} */

.input-container {
  position: relative;
  margin-top: 10px;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
}

.input-field {
  width: 100%;
  padding: 10px 35px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.loading {
  margin-top: 10px;
  color: #666;
}

.results-container {
  margin-top: 15px;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.search-results {
  list-style: none;
  padding: 0;
  margin: 0;
}

.result-item {
  padding: 8px 12px;
  border-bottom: 1px solid #f0f0f0;
  font-size: 14px;
}

.result-item:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}

.no-results {
  margin-top: 10px;
  color: #999;
  font-style: italic;
}

.icon {
  margin-right: 10px;
  font-size: 1.2rem;
  color: gray; 
}
.blue {
  color: #007bff;
}

.orange {
  color: #ffa500;
}

.green {
  color: #28a745;
}

.purple {
  color: #6f42c1;
}

.teal {
  color: #20c997;
}

.gold {
  color: #ffc107;
}

.auth-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;
}
